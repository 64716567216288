export const NUM_DAYS = 366
export const DAY_STEP = 1

export const NUM_MONTHS = 12
export const MONTH_STEP = 1

const endOfDays = []
for (let i = 0; i < NUM_DAYS; i += DAY_STEP) {
  let date = new Date();
  date.setDate(date.getDate() - i + 1)
  date.setUTCHours(0, 0, 0)
  endOfDays.push(date.toISOString())
}
export default endOfDays


export const periods = [
  {
    id: 0,
    name: "Last 7 days",
    days: 7,
    step: 1
  },
 
  {
    id: 1,
    name: "Last 30 days",
    days: 30,
    step: 1
  },
 
  {
    id: 2,
    name: "Last 3 months",
    days: 90,
    step: 3
  },
  {
    id: 3,
    name: "Last 6 months",
    days: 180,
    step: 6
  },
  {
    id: 4,
    name: "Last 12 Months",
    days: 365,
    step: 12
  }
]


export const endOfMonths = []

for (let i = 0; i < NUM_MONTHS; i += MONTH_STEP) {
  let d = new Date(); 
  d.setMonth(d.getMonth() - i + 1, 1) // first of next month
  d.setDate(d.getDate() - 1) // last of this month
  d.setUTCHours(0, 0, 0)
  endOfMonths.push(d.toISOString())
}


export const monthlyPeriods = [
  {
    id: 0,
    name: "Last 3 months",
    months: 3,
    step: 1
  },
  {
    id: 1,
    name: "Last 6 months",
    months: 6,
    step: 1
  },
  {
    id: 2,
    name: "Last 12 Months",
    months: 12,
    step: 1
  }
]