<template>
  <b-overlay :show="fetchingState !== 'FETCHED'" spinner-variant="primary">
    <template #overlay v-if="fetchingState === 'FAILED'">
      <div>Something went wrong</div>
      <div class="text-center">
        <font-awesome-icon
          :icon="['fal', 'redo']"
          class="btn-icon"
          @click="fetchLeadsClients"
        />
      </div>
    </template>
    <b-card
      class="leads-clients-chart"
      header-class="card-settings__header border-bottom"
    >
      <template #header>
        <div class="d-flex justify-content-between w-100 position-relative">
          <div class="font-weight-bold font-small-3">Leads / Clients</div>
        </div>
      </template>

      <b-card-body>
        <div>
          <div v-if="chartData">
            <DoughnutChart
              :chart-data="chartData"
              :options="chartOptions.doughnutChart.options"
              :height="200"
              class="mt-1"
            />
          </div>
          <div
            v-else
            class="d-flex mt-1 justify-content-center align-items-center"
            style="height: 200px"
          ></div>

          <!-- chart info -->
          <b-container class="px-3">
            <b-row class="m-1" no-gutters>
              <b-col cols="7">
                <div class="mb-tiny d-flex">
                  <div
                    class="dot my-auto mr-small"
                    style="background: #ff6d00"
                  />
                  Leads
                </div>
              </b-col>
              <b-col cols="3" v-if="leadsClients">
                <span>{{ leads }}</span>
              </b-col>
              <b-col cols="2" v-if="leadsClients" class="text-right">
                <span>
                  {{ ((leads * 100) / (leads + clients)).toFixed(2) }}%
                </span>
              </b-col>
            </b-row>
            <b-row class="m-1" no-gutters>
              <b-col cols="7">
                <div class="mb-tiny d-flex">
                  <div
                    class="dot my-auto mr-small"
                    style="background: #fff001"
                  />
                  Clients (Unfunded)
                </div>
              </b-col>
              <b-col cols="3" class="m-0" v-if="leadsClients">
                <span>{{ clients - funded }}</span>
              </b-col>
              <b-col cols="2" class="p-0 text-right" v-if="leadsClients">
                <span>
                  {{
                    (((clients - funded) * 100) / (leads + clients)).toFixed(2)
                  }}%
                </span>
              </b-col>
            </b-row>
            <b-row class="m-1" no-gutters>
              <b-col cols="7">
                <div class="mb-tiny d-flex">
                  <div
                    class="dot my-auto mr-small"
                    style="background: #00c853"
                  />
                  Clients (Funded)
                </div>
              </b-col>
              <b-col cols="3" class="m-0" v-if="leadsClients">
                <p class="mb-tiny">{{ funded }}</p>
              </b-col>
              <b-col cols="2" class="p-0 text-right" v-if="leadsClients">
                <p class="mb-tiny">
                  {{ ((funded * 100) / (leads + clients)).toFixed(2) }}%
                </p>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import DoughnutChart from "@/components/chartjs/DoughnutChart.vue";
import chartOptions from "@/components/chartjs/chartOptions";
import axios from "@axios";
export default {
  components: {
    DoughnutChart,
  },
  props: ["leads", "funded", "clients"],
  data() {
    return {
      chartOptions,
      leadsClients: true,
      fetchingState: "FETCHED",
    };
  },
  methods: {
    async fetchLeadsClients() {
      try {
        this.fetchingState = "FETCHING";
        let res = await axios.get(
          `${localStorage.getItem(
            "VUE_APP_API_URL"
          )}//metric/ClientsLeads/data/LeadsClients`
        );
        this.leadsClients = res.data;
        this.fetchingState = "FETCHED";
      } catch (error) {
        console.error(error);
        this.fetchingState = "FAILED";
      }
    },
  },
  computed: {
    chartData() {
      if (!this.leadsClients) return null;
      return {
        datasets: [
          {
            labels: ["Leads", "Clients (Unfunded)", "Clients (Funded)"],
            data: [this.leads, this.clients - this.funded, this.funded],
            backgroundColor: ["#FF6D00", "#FFF001", "#00C853"],
            borderWidth: 0,
            pointStyle: "rectRounded",
          },
        ],
      };
    },
  },
  mounted() {
    // this.fetchLeadsClients()
  },
};
</script>

<style>
.dot {
  width: 10px;
  height: 10px;
  border-radius: 5px;
}
</style>
