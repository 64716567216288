<template>
  <b-overlay :show="fetchingState !== 'FETCHED'" spinner-variant="primary">
    <template #overlay v-if="fetchingState === 'FAILED'">
      <div class="text-default">Something went wrong</div>
      <div class="text-center">
        <font-awesome-icon
          :icon="['fal', 'redo']"
          class="btn-icon text-default"
          @click="fetchHistory"
        />
      </div>
    </template>
    <b-card
      class="leads-clients-chart"
      header-class="card-settings__header  card-header-border"
    >
      <template #header>
        <div class="col-6 pl-0 font-weight-bold font-small-3 text-default">
          Leads / Clients
        </div>
      </template>

      <b-card-body
        class="d-flex justify-content-between w-100"
        style="height: 340px"
      >
        <div class="trapezoid mt-3">
          <div class="child trapezoid-leads"></div>
          <div class="child trapezoid-clients"></div>
          <div class="child trapezoid-funded"></div>
          <div class="child trapezoid-active"></div>
          <div class="child trapezoid-text">
            <div class="trapezoid-text-item">
              <div>
                {{ leads }}
              </div>
              <div>
                Leads
              </div>
            </div>
            <div class="trapezoid-text-item">
              <div>
                {{ clients }}
              </div>
              <div>
                Clients
              </div>
            </div>
            <div class="trapezoid-text-item">
              <div>
                {{ funded }}
              </div>
              <div>
                Funded
              </div>
            </div>
            <div class="trapezoid-text-item">
              <div>
                {{ active }}
              </div>
              <div>
                Active
              </div>
            </div>
          </div>
        </div>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import vSelect from "vue-select";
import axios from "@axios";

export default {
  components: {
    vSelect,
  },
  props: ["leads", "clients", "funded", "active"],
  data() {
    return {
      data: null,
      fetchingState: "FETCHED",
    };
  },
  methods: {
    compareTime(firstEl, secondEl) {
      if (firstEl.timestamps > secondEl.timestamps) return 1;
      if (firstEl.timestamps < secondEl.timestamps) return -1;
      return 0;
    },
    async fetchHistory() {
      try {
        this.fetchingState = "FETCHING";
        let res = await axios.get(
          `${localStorage.getItem("VUE_APP_API_URL")}//metric/ClientsLeads/data`
        );
        this.data = res.data[0];
        this.fetchingState = "FETCHED";
        // console.log(this.leadHistory)
      } catch (error) {
        this.fetchingState = "FAILED";
        console.error(error);
      }
    },
  },

  // computed: {
  //   leads() {
  //     if (!this.data) return 0

  //     return this.data.value['#Leads']
  //   },
  //   clients() {
  //     if (!this.data) return 0

  //     return this.data.value['#Clients']
  //   },
  //   funded() {
  //     if (!this.data) return 0

  //     return this.data.value['#Funded']
  //   },
  //   active() {
  //     if (!this.data) return 0

  //     return this.data.value['#Active'].length
  //   }
  // },
  mounted() {
    // this.fetchHistory()
  },
};
</script>

<style scoped>
.trapezoid {
  position: relative;
  width: 280px;
  height: 300px;
  margin: auto;
}

.trapezoid .child {
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  position: absolute;
  text-align: center;
}

.trapezoid-leads {
  border-top: 65px solid #2d3436;
  width: 280px;
}
.trapezoid-clients {
  border-top: 65px solid #ff6d00;
  width: 240px;
  left: 20px;
  top: 65px;
}
.trapezoid-funded {
  border-top: 65px solid #6fcf97;
  width: 200px;
  left: 40px;
  top: 130px;
}
.trapezoid-active {
  border-top: 65px solid #00c853;
  width: 160px;
  left: 60px;
  top: 195px;
}
.trapezoid-text {
  width: 100%;
  color: white;
}
.trapezoid-text-item {
  display: block;
  height: 65px;
  padding-top: 10px;
}
</style>
