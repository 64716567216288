<template>
  <b-card>
    <section class="card-header p-1">
      <div>
        <h1 class="mb-0">{{ computedTitle }}</h1>
      </div>
      <div>
        <div class="card-icon-outer" :style="{ color: iconBackgroundColor }">
          <font-awesome-icon class="card-icon" :icon="icon" :style="{ color: iconColor }" />
        </div>
      </div>
    </section>
    <div class="pl-1 pb-1">
      {{ titleDesc }}
    </div>
  </b-card>
</template>

<script>
export default {
  props: {
    title: [String, Number],
    titleDesc: String,
    iconColor: {
      type: String,
      default: 'rgb(0, 213, 99)'
    },
    icon: {
      type: Array,
      default: ['fal', 'check']
    }
  },
  computed: {
    iconBackgroundColor() {
      return this.iconColor.replace('rgb', 'rgba').replace(')', ',0.12)')
    },
    computedTitle() {
      if (typeof this.title === 'number') {
        // round to 2 decimal places
        return Math.round(this.title * 100) / 100
      }
      return this.title
    }
  }
}
</script>

<style scoped>
.card-icon-outer {
  all: initial;
  display: block;
  position: relative;
  width: 35px;
  height: 35px;
}
.card-icon {
  all: initial;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>