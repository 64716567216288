const FETCHING_STATES = ['FETCHING', 'FETCHED', 'FAILED']
export default {
	data() {
		return {
			fetchingState: ['FETCHING']
		}
	},
	methods: {
		startFetching(index = 0) {
			this.$set(this.fetchingState, index, 'FETCHING')
		},
		failFetching(index = 0) {
			this.$set(this.fetchingState, index, 'FAILED')
		},
		doneFetching(index = 0) {
			this.$set(this.fetchingState, index, 'FETCHED')
		},
	},
	computed: {
		isDataFetching() {
			return this.fetchingState.some(state => state === 'FETCHING')
		},
		isDataFetched() {
			return this.fetchingState.every(state => state === 'FETCHED')
		},
		isDataFetchingFailed() {
			return this.fetchingState.some(state => state === 'FAILED')
		}
	}
}