<template>
  <b-card class="overflow-hidden">
    <b-overlay :show="state === 'FETCHING'" spinner-variant="primary" rounded>
      <section class="card-header card-settings__header border-bottom">
        <div class="col-6 font-weight-bold font-small-3 px-50 text-default">Commission (USD)</div>
        <div class="col-6 d-flex justify-content-end px-50">
          <b-dropdown no-caret variant="flat-dark" right class="my-dropdown font-small-3 ml-50" size="sm">
            <template #button-content>
              {{ periods[selectedPeriod].name }} 
              <font-awesome-icon :icon="['fal', 'chevron-down']" class="ml-50" />
            </template>
            <b-dropdown-item 
              v-for="(item, index) in periods" 
              :key="index" 
              @click="selectedPeriod = index"> 
              {{ item.name }} 
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </section>
          
      <b-card-body>
        <div>
          <div class="pr-2">
            <VueApexCharts
              :series="chartSeries"
              :options="chartOptions"
              type="area"
              height="300"
            />
          </div>
        </div>
      </b-card-body>
    </b-overlay>
  </b-card>
   
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import apexChartData from '@/components/chartjs/chartData.js'
import axios from '@axios'
import { mapActions, mapGetters } from 'vuex'
import { periods } from '@/utils/endOfDays'
import moment from 'moment'

export default {
  components: {
    VueApexCharts,
  },
  props: {
    histories: {
      type: Array,
      default: () => []
    },
    state: {
      type: String,
      default: 'FETCHING'
    },
    todayValue: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      periods,
      selectedPeriod: 0,
      apexChartData,
    }
  },
  methods: {
    ...mapActions({
      fetchConversionRate: 'rate/fetchConversionRate'
    }),
  },
  computed: {
    ...mapGetters({
      currentIbId: 'partner/currentIbId',
      rates: 'rate/getAllRates'
    }),
    chartSeries() {
      if (this.state != 'FETCHED') return [ { name: '', data: [] } ]
      let option = this.periods[this.selectedPeriod]
      let num = option.days;
      let data = this.histories.sort((a, b) => (a[0] > b[0]) - (a[0] < b[0]))
        .filter(history => moment(history[0]).isAfter(moment().subtract(num, "days")))
        .map(history => [ moment.utc(history[0]).startOf("day").valueOf(), history[1] ])
      data.push([
        moment.utc().startOf("day").valueOf(),
        this.todayValue
      ])
      // console.log(this.todayValue, data)
      return [
        {
          name:'',
          data
        }
      ]
    },
    chartOptions() {
      return {
        ...apexChartData.lineAreaChartSpline.chartOptions,
        yaxis: {
          labels: {
            formatter: (amount) => {
              let parts = amount.toString().replace(",", "").split(".");
              return parts.join(".");
            },
          },
        },
      
      };
    }, 
  },
}
</script>
