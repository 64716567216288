<template>
  <div>
    <b-row>
      <b-col cols="2">
        <TheCard
          :title="commissionThisMonth"
          titleDesc="Commission This Month"
          :icon="['fal', 'sack-dollar']"
        />
      </b-col>
      <b-col cols="2">
        <TheCard
          :title="totalEquity"
          titleDesc="Total Equity Today"
          :icon="['fal', 'landmark']"
          iconColor="rgb(16, 156, 241)"
        />
      </b-col>
      <b-col cols="2">
        <TheCard
          :title="netDepositThisMonth"
          titleDesc="Net Deposit This Month (USD)"
          :icon="['fal', 'inbox-in']"
          iconColor="rgb(255, 109, 0)"
        />
      </b-col>
      <b-col cols="2">
        <TheCard
          :title="newClientsThisMonth + ' / ' + newLeadsThisMonth"
          titleDesc="New Clients / Leads This Month"
          :icon="['fal', 'user-chart']"
          iconColor="rgb(187, 107, 217)"
        />
      </b-col>
      <b-col cols="2">
        <TheCard
          :title="newFundedThisMonth"
          titleDesc="New Funded Clients This Month"
          :icon="['fal', 'hand-holding-seedling']"
        />
      </b-col>
      <b-col cols="2">
        <TheCard
          :title="activeThisMonth"
          titleDesc="Active Clients This Month"
          :icon="['fal', 'users']"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <b-card class="p-1" style="min-height: 175px">
          <div class="mb-75">IB ID: {{ currentIb.id }}</div>
          <div class="mb-75" v-if="currentIb.wallet">
            Wallet: W{{ currentIb.wallet.id }} {{ currentIb.wallet.currency }}
          </div>
          <div class="mb-75" v-if="currentIb.commission_profile">
            Commission Profile: {{ currentIb.commission_profile.name }}
          </div>
          <div class="mb-75" v-if="currentIb && currentIb.ib_code">Reference Code: {{ currentIb.ib_code }}</div>
          <div v-if="currentIb && currentIb.ib_code">
            Reference Link: {{ portal_url }}/register?referral_id={{
              currentIb.ib_code
            }}&utm_source=ib&utm_medium=referral
            <span
              v-clipboard:copy="
                `${portal_url}/register?referral_id=${currentIb.ib_code}&utm_source=ib&utm_medium=referral`
              "
              v-clipboard:success="() => onCopy"
            >
              <font-awesome-icon
                :icon="['fal', 'clone']"
                class="btn-icon ml-2"
                size="lg"
              />
            </span>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <CommissionChart
          :today-value="values['Commission']"
          :histories="commissionHistories"
          :state="fetchingState[0] && fetchingState[1]"
        />
      </b-col>
      <b-col cols="6">
        <FundingChart
          :today-values="values"
          :histories="fundingHistories"
          :state="fetchingState[2] && fetchingState[0]"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <TotalEquityChart
          :today-value="values['Equity']"
          :histories="equityHistories"
          :state="fetchingState[3] && fetchingState[0]"
        />
      </b-col>
      <b-col cols="6">
        <ActiveClientChart
          :today-value="values['ActiveClients']"
          :histories="activeClientHistories"
          :state="fetchingState[4] && fetchingState[0]"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="3">
        <LeadsClientsFunnelChart
          :leads="leadCount"
          :clients="clientCount"
          :funded="fundedCount"
          :active="activeThisMonth"
        />
      </b-col>
      <b-col cols="3">
        <LeadsClientsDoughnutChart
          :leads="leadCount"
          :clients="clientCount"
          :funded="fundedCount"
        />
      </b-col>
      <b-col cols="6">
        <TradingVolumeChart
          :today-value="values['TradingVolume']"
          :histories="tradingVolumeHistories"
          :state="fetchingState[5] && fetchingState[0]"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from "@axios";
import vSelect from "vue-select";
import TheCard from "./TheCard.vue";
import LeadsClientsFunnelChart from "./charts/LeadsClientsFunnelChart.vue";
import LeadsClientsDoughnutChart from "./charts/LeadsClientsDoughnutChart.vue";
import ActiveClientChart from "./charts/ActiveClientChart.vue";
import CommissionChart from "./charts/CommissionChart.vue";
import TotalEquityChart from "./charts/TotalEquityChart.vue";
import TradingVolumeChart from "./charts/TradingVolumeChart.vue";
import FundingChart from "./charts/FundingChart.vue";
import { notifyError, notifySuccess, formatMoney } from "@/@core/utils";
import { mapGetters, mapActions } from "vuex";
import fetchingState from "@/mixins/fetchingState";
import moment from "moment";

export default {
  components: {
    vSelect,
    TheCard,
    LeadsClientsFunnelChart,
    LeadsClientsDoughnutChart,
    ActiveClientChart,
    CommissionChart,
    TotalEquityChart,
    TradingVolumeChart,
    FundingChart,
  },
  mixins: [fetchingState],
  data() {
    return {
      values: {},
      commissionHistories: [],
      tradingVolumeHistories: [],
      fundingHistories: [],
      equityHistories: [],
      activeClientHistories: [],
    };
  },
  methods: {
    formatMoney,
    ...mapActions({
      fetchConversionRate: "rate/fetchConversionRate",
    }),
    async fetchMetricDatum() {
      try {
        this.startFetching(0);
        const { data } = await axios.get(
          `${localStorage.getItem("VUE_APP_API_URL")}/ibs/${
            this.currentIbId
          }/get_metric_datum`
        );
        this.values = data.metric_datum.value || {};
        this.doneFetching(0);
      } catch (error) {
        console.error(error);
        this.failFetching(0);
      }
    },
    async fetchCommissionHistories() {
      try {
        this.startFetching(1);
        const { data } = await axios.get(
          `${localStorage.getItem("VUE_APP_API_URL")}/ibs/${
            this.currentIbId
          }/get_commission_histories`
        );
        this.commissionHistories = data.histories;
        this.doneFetching(1);
      } catch (error) {
        console.error(error);
        this.failFetching(1);
      }
    },
    async fetchFundingHistories() {
      try {
        this.startFetching(2);
        const { data } = await axios.get(
          `${localStorage.getItem("VUE_APP_API_URL")}/ibs/${
            this.currentIbId
          }/get_funding_histories`
        );
        this.fundingHistories = data.histories;
        this.doneFetching(2);
      } catch (error) {
        console.error(error);
        this.failFetching(2);
      }
    },
    async fetchEquityHistories() {
      try {
        this.startFetching(3);
        const { data } = await axios.get(
          `${localStorage.getItem("VUE_APP_API_URL")}/ibs/${
            this.currentIbId
          }/get_equity_histories`
        );
        this.equityHistories = data.histories;
        this.doneFetching(3);
      } catch (error) {
        console.error(error);
        this.failFetching(3);
      }
    },
    async fetchActiveClientHistories() {
      try {
        this.startFetching(4);
        const { data } = await axios.get(
          `${localStorage.getItem("VUE_APP_API_URL")}/ibs/${
            this.currentIbId
          }/get_active_client_histories`
        );
        this.activeClientHistories = data.histories;
        this.doneFetching(4);
      } catch (error) {
        console.error(error);
        this.failFetching(4);
      }
    },
    async fetchTradingVolumeHistories() {
      try {
        this.startFetching(5);
        const { data } = await axios.get(
          `${localStorage.getItem("VUE_APP_API_URL")}/ibs/${
            this.currentIbId
          }/get_trading_volume_histories`
        );
        this.tradingVolumeHistories = data.histories;
        this.doneFetching(5);
      } catch (error) {
        console.error(error);
        this.failFetching(5);
      }
    },
  },
  computed: {
    ...mapGetters({
      currentIbId: "partner/currentIbId",
    }),
    portal_url() {
      const app_env_obj = JSON.parse(localStorage.APP_ENV);
      let identity = localStorage.APP_IDENTITY;
      let i;
      for (i = 0; i < app_env_obj.length; i++) {
        if (app_env_obj[i].name == identity) {
          return app_env_obj[i].portal_url;
        }
      }
    },

    onCopy() {
      notifySuccess("Copied to clipboard!");
    },

    currentIb() {
      return this.$store.getters["partner/currentIb"];
    },
    commissionThisMonth() {
      let monthStart = moment.utc().startOf("month");
      let result = this.commissionHistories
        .filter((history) => monthStart.isBefore(moment(history[0])))
        .reduce((sum, history) => sum + parseFloat(history[1]), 0);
      result += this.values?.Commission;
      return result;
    },
    totalEquity() {
      return formatMoney(this.values?.Equity, 2);
    },
    netDepositThisMonth() {
      let monthStart = moment.utc().startOf("month");
      let result = this.fundingHistories
        .filter((history) => monthStart.isBefore(moment(history.timestamp)))
        .reduce((sum, history) => sum + parseFloat(history.NetDeposit), 0);
      result += this.values?.Deposit - this.values?.Withdrawal;
      return formatMoney(result, 2);
    },
    newLeadsThisMonth() {
      return this.currentIb.new_leads_this_month ?? 0;
    },

    newClientsThisMonth() {
      return this.currentIb.new_clients_this_month ?? 0;
    },

    newFundedThisMonth() {
      return this.currentIb.new_funded_this_month ?? 0;
    },

    activeThisMonth() {
      return this.values?.ActiveClients;
    },

    leadCount() {
      return this.values["#Leads"] ?? 0;
    },

    clientCount() {
      return this.values["#Clients"] ?? 0;
    },

    fundedCount() {
      return this.values["#Funded"] ?? 0;
    },
  },
  watch: {
    currentIbId: {
      immediate: true,
      handler(newVal) {
        if (newVal > 0) {
          this.fetchMetricDatum();
          this.fetchCommissionHistories();
          this.fetchFundingHistories();
          this.fetchEquityHistories();
          this.fetchActiveClientHistories();
          this.fetchTradingVolumeHistories();
        }
      },
    },
  },
};
</script>
<style>
.dashboard-icon-wrapper {
  background: #03b14f;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  position: relative;
}

.dashboard-icon {
  position: absolute;
  top: 4px;
  left: 3px;
}
</style>
